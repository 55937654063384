<template>

  <!--FOOTER-->
  <footer class="uk-section uk-section-small uk-section-muted" id="footer">
    <div class="uk-container">
      <div class="uk-width-1-1@m uk-flex uk-flex-center"> 
          <div class="uk-flex uk-flex-middle">
            <img src="../../assets/footer.png" alt="" class="uk-align-center" width="300" style="">
          </div> 
      </div>
    </div>

  </footer>
  <!--/FOOTER-->
</template>

<script>

  export default {

  }
</script>


<style scoped>
  #footer {
    background-color: rgba(173, 173, 171, 0.342);
  }



</style>
