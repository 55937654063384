<template>
  <article class="tm-product-card" style="border: 1px solid #eee;" :class="classes[item.datatype-1].class">
    <div class="tm-product-card-media">
      <div class="tm-ratio tm-ratio-4-3">

            <router-link
            class="tm-media-box"
            :title="item.title"
            tag="a"
            :to="{
              name: 'answer',
              params: {
                answerId: item.id
              }
            }"
          >
          <div class="tm-product-card-labels">
            <h5><span v-if="!item.free && item.discount > 0" class="uk-label uk-label-danger uk-text-bold">SALE</span></h5>
            <!-- <span class="uk-label uk-label-success">trade-in</span> -->
          </div>
          <figure style="overflow: hidden;" class="tm-media-box-wrap">
            <div class="lazy uk-width-1-1"   v-if="item.datatype == 2">
              <video class="lazy uk-position-center" poster :src="item.imageurl">
                <source :src="item.imageurl" type="video/mp4" size="720">
                <source :src="item.imageurl" type="video/mp4" size="1080">
                <!-- <track kind="captions" label="English" srclang="kr"  default> -->
              </video>
            </div>
            <vue-plyr   style="max-width: 100%;"  v-else-if="item.datatype == 3">
              <audio>
                <source :src="item.imageurl" type="audio/mp3">
                <source :src="item.imageurl" type="audio/ogg">
              </audio>
            </vue-plyr>
            <div v-else class="lazy uk-width-1-1">
            <img

              class="lazy uk-position-center"
              style="max-width: 100%;"
              :data-src="itemm.imageurl"
              data-uk-img
              alt
            >
            </div>
          </figure>
        </router-link>
      </div>
    </div>
    <div class="tm-product-card-body">
      <div class="tm-product-card-info">
        <div class="uk-text-meta uk-margin-xsmall-bottom">{{classes[item.datatype-1].class}}</div>
        <h3 class="tm-product-card-title">


          <router-link
            class="uk-link-heading"
            :title="item.title"
            tag="a"
            :to="{
              name: 'answer',
              params: {
                answerId: item.id
              }
            }"
          >{{ item.description | shortDescription}}</router-link>
          <!-- {{ item.description | shortDescription}} -->
        </h3>
        <!-- <ul class="uk-list uk-text-small tm-product-card-properties">
          <li>
            <span class="uk-text-muted">Diagonal display:</span>
            <span>15.4"</span>
          </li>
          <li>
            <span class="uk-text-muted">CPU:</span>
            <span>Intel®&nbsp;Core™ i7</span>
          </li>
          <li>
            <span class="uk-text-muted">RAM:</span>
            <span>16&nbsp;GB</span>
          </li>
          <li>
            <span class="uk-text-muted">Video Card:</span>
            <span>AMD Radeon Pro 555</span>
          </li>
        </ul> -->
      </div>
      <div class="tm-product-card-shop">
        <!-- {{item.free}} -->
        <div v-if="!item.free" class="tm-product-card-prices">

          <del v-if="item.discount > 0" class="uk-text-meta">{{item.price}} Point</del>
          <div class="tm-product-card-price">{{item.price -((item.price * item.discount)/100)}} Point</div>
        </div>
        <div class="tm-product-card-prices" v-else><span>Free</span></div>

        <div class="tm-product-card-add">
          <div class="uk-text-meta tm-product-card-actions">
            <!-- <a
              class="tm-product-card-action js-add-to js-add-to-favorites tm-action-button-active js-added-to"
              title="Add to favorites"
            >
              <span uk-icon="icon: heart; ratio: .75;"></span>
              <span class="tm-product-card-action-text">Add to favorites</span>
            </a>
            <a
              class="tm-product-card-action js-add-to js-add-to-compare tm-action-button-active js-added-to"
              title="Add to compare"
            >
              <span uk-icon="icon: copy; ratio: .75;"></span>
              <span class="tm-product-card-action-text">Add to compare</span>
            </a> -->
          </div>
          <button type="button" @click="addItem"
            class="uk-button uk-button-primary tm-product-card-add-button tm-shine js-add-to-cart"
          >
            <span  class="tm-product-card-add-button-icon" uk-icon="cart"></span>
            <span  class="tm-product-card-add-button-text">add to cart</span>
          </button>
        </div>
      </div>
    </div>
  </article>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  props: ['item', 'displayList'],
  data: function () {
    return {
      itemm: this.item,
      image: '',
      imagedata: null,
      classes: [
        { class: 'image' },
        { class: 'video' },
        { class: 'audio' },
        { class: 'stack' }
      ]
    }
  },
  methods: {
    ...mapActions(['updateCart']),
    addItem () {
      const order = {
        item: Object.assign({}, this.item),
        quantity: 1,
        isAdd: true,
        type: 'data'
      }
      this.updateCart(order)
    },
    displayType () {
      return this.classes[this.itemm.datatype].class
    }
  },
  filters: {
    shortDescription (value) {
      if (value && value.length > 100) {
        return value.substring(0, 100) + '...'
      } else {
        return value
      }
    }
  },
  mounted () {}
}
</script>

<style scoped>
</style>
