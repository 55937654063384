var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('article',{staticClass:"tm-product-card",class:_vm.classes[_vm.item.datatype-1].class,staticStyle:{"border":"1px solid #eee"}},[_c('div',{staticClass:"tm-product-card-media"},[_c('div',{staticClass:"tm-ratio tm-ratio-4-3"},[_c('router-link',{staticClass:"tm-media-box",attrs:{"title":_vm.item.title,"tag":"a","to":{
            name: 'answer',
            params: {
              answerId: _vm.item.id
            }
          }}},[_c('div',{staticClass:"tm-product-card-labels"},[_c('h5',[(!_vm.item.free && _vm.item.discount > 0)?_c('span',{staticClass:"uk-label uk-label-danger uk-text-bold"},[_vm._v("SALE")]):_vm._e()])]),_c('figure',{staticClass:"tm-media-box-wrap",staticStyle:{"overflow":"hidden"}},[(_vm.item.datatype == 2)?_c('div',{staticClass:"lazy uk-width-1-1"},[_c('video',{staticClass:"lazy uk-position-center",attrs:{"poster":"","src":_vm.item.imageurl}},[_c('source',{attrs:{"src":_vm.item.imageurl,"type":"video/mp4","size":"720"}}),_c('source',{attrs:{"src":_vm.item.imageurl,"type":"video/mp4","size":"1080"}})])]):(_vm.item.datatype == 3)?_c('vue-plyr',{staticStyle:{"max-width":"100%"}},[_c('audio',[_c('source',{attrs:{"src":_vm.item.imageurl,"type":"audio/mp3"}}),_c('source',{attrs:{"src":_vm.item.imageurl,"type":"audio/ogg"}})])]):_c('div',{staticClass:"lazy uk-width-1-1"},[_c('img',{staticClass:"lazy uk-position-center",staticStyle:{"max-width":"100%"},attrs:{"data-src":_vm.itemm.imageurl,"data-uk-img":"","alt":""}})])],1)])],1)]),_c('div',{staticClass:"tm-product-card-body"},[_c('div',{staticClass:"tm-product-card-info"},[_c('div',{staticClass:"uk-text-meta uk-margin-xsmall-bottom"},[_vm._v(_vm._s(_vm.classes[_vm.item.datatype-1].class))]),_c('h3',{staticClass:"tm-product-card-title"},[_c('router-link',{staticClass:"uk-link-heading",attrs:{"title":_vm.item.title,"tag":"a","to":{
            name: 'answer',
            params: {
              answerId: _vm.item.id
            }
          }}},[_vm._v(_vm._s(_vm._f("shortDescription")(_vm.item.description)))])],1)]),_c('div',{staticClass:"tm-product-card-shop"},[(!_vm.item.free)?_c('div',{staticClass:"tm-product-card-prices"},[(_vm.item.discount > 0)?_c('del',{staticClass:"uk-text-meta"},[_vm._v(_vm._s(_vm.item.price)+" Point")]):_vm._e(),_c('div',{staticClass:"tm-product-card-price"},[_vm._v(_vm._s(_vm.item.price -((_vm.item.price * _vm.item.discount)/100))+" Point")])]):_c('div',{staticClass:"tm-product-card-prices"},[_c('span',[_vm._v("Free")])]),_c('div',{staticClass:"tm-product-card-add"},[_c('div',{staticClass:"uk-text-meta tm-product-card-actions"}),_c('button',{staticClass:"uk-button uk-button-primary tm-product-card-add-button tm-shine js-add-to-cart",attrs:{"type":"button"},on:{"click":_vm.addItem}},[_c('span',{staticClass:"tm-product-card-add-button-icon",attrs:{"uk-icon":"cart"}}),_c('span',{staticClass:"tm-product-card-add-button-text"},[_vm._v("add to cart")])])])])])])}
var staticRenderFns = []

export { render, staticRenderFns }