<template>
    <div class="Home">
      <page-header/>
      <section class="uk-section uk-section-small uk-padding-remove">
        <div class="uk-container">
          <div class="uk-height-large uk-cover-container uk-border-rounded">
            <img src="https://picsum.photos/1300/500/?random" alt="Alt img" data-uk-cover>
            <div
              class="uk-overlay uk-overlay-primary uk-position-cover uk-flex uk-flex-center uk-flex-middle uk-light uk-text-center"
            >
              <div data-uk-scrollspy="cls: uk-animation-slide-bottom-small">
                <h3
                  class="uk-margin-top uk-margin-small-bottom uk-margin-remove-adjacent uk-padding-small"
                v-html="$t('ubtMarket.title')" >

                </h3>
                <form>
                  <div class="uk-grid-small uk-flex-center uk-grid" uk-grid>
                    <div class="uk-width-expand uk-first-column">
                      <div class="uk-inline uk-width-1-1">
                        <span class="uk-form-icon uk-icon" uk-icon="search"></span>
                        <input class="uk-input" type="text" placeholder="Search .." required/>
                      </div>
                    </div>
                    <div>
                      <button type="button" class="uk-button uk-button-primary">{{$t('ubtMarket.search')}}</button>
                    </div>
                  </div>
                </form>

                <p></p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section
        class="uk-section uk-section-small uk-section-default"
        data-uk-filter="target: .js-filter"
      >
        <div class="uk-container">
          <div class="tm-change-view uk-margin-small-left">
            <ul class="uk-subnav uk-iconnav js-change-view" uk-switcher hidden>
              <li>
                <a
                  class="uk-active"
                  data-view="grid"
                  uk-icon="grid"
                  uk-tooltip="Grid"
                  @click.prevent="changeDisplay(false)"
                ></a>
              </li>
              <li>
                <a
                  data-view="list"
                  uk-icon="list"
                  uk-tooltip="List"
                  @click.prevent="changeDisplay(true)"
                ></a>
              </li>
            </ul>
          </div>
          <ul class="uk-subnav uk-subnav-pill">
            <li class="uk-active" data-uk-filter-control>
              <a href="#">{{$t('ubtMarket.all')}}</a>
            </li>
            <li data-uk-filter-control=".image">
              <a href="#">{{$t('ubtMarket.img')}}</a>
            </li>
            <li data-uk-filter-control=".stack">
              <a href="#">{{$t('ubtMarket.stack')}}</a>
            </li>
            <li data-uk-filter-control=".video">
              <a href="#">{{$t('ubtMarket.video')}}</a>
            </li>
            <li data-uk-filter-control=".audio">
              <a href="#">{{$t('ubtMarket.audio')}}</a>
            </li>
            <li>
              <router-link
                class="uk-link-muted uk-text-uppercase tm-link-to-all"
                title="see all"
                tag="a"
                :to="{
              name: 'datamarket'
            }"
              >
                <span>{{$t('ubtMarket.rMore')}}</span>
                <span uk-icon="icon: chevron-right; ratio: .75;" class="uk-icon"></span>
              </router-link>
            </li>

            <!-- <div class="btn-group pull-right">
              <a id="list" class="btn btn-default btn-sm" @click.prevent="changeDisplay(true)">
                <span class="glyphicon glyphicon-th-list"></span>List
              </a>
              <a id="grid" class="btn btn-default btn-sm" @click.prevent="changeDisplay(false)">
                <span class="glyphicon glyphicon-th"></span>Grid
              </a>
            </div>-->
          </ul>

          <div
            class="uk-grid uk-grid-medium uk-child-width-1-2@s uk-child-width-1-3@m uk-child-width-1-4@l uk-child-width-1-4@xl uk-grid-match js-filter uk-grid-collapse"
            :class="displayList"
            data-uk-grid="masonry: true"
            data-uk-sortable="handle: .drag-icon"
          >
            <app-answer-item
              v-for="ans in answers"
              :item="ans"
              :key="ans.id"
              :displayList="displayList"
            ></app-answer-item>
          </div>
          <div class="uk-flex uk-flex-center">
            <grid-loader
              class="uk-margin"
              :loading="isLoading"
              :color="loaderColor"
              :size="loaderSize"
            ></grid-loader>
          </div>
          <div class="uk-text-center uk-margin">
            <router-link
              class="uk-link-muted uk-text-uppercase tm-link-to-all"
              title="see all"
              tag="a"
              :to="{
              name: 'datamarket'
            }"
            >
              <span>{{$t('ubtMarket.rMore')}}</span>
              <span uk-icon="icon: chevron-right; ratio: .75;" class="uk-icon"></span>
            </router-link>
          </div>
        </div>
      </section>
      <page-footer/>
    </div>

</template>

<script scoped>
import AnswersService from '@/services/AnswersService'
import AnswerItem from '@/components/globitemviews/Answer/AnswerItemm'
import GridLoader from 'vue-spinner/src/GridLoader.vue'
import PageHeader from '@/components/Homecmp/Header.vue'
import PageFooter from '@/components/Homecmp/Footer.vue'

export default {
  name: 'Home',
  components: {

    appAnswerItem: AnswerItem,
    GridLoader,
    PageHeader,
    PageFooter
  },
  data () {
    return {
      answers: null,
      displayList: false,
      isLoading: false,
      loaderColor: 'rgb(1, 176, 255)',
      loaderSize: '25px'
    }
  },

  created () {
    this.isLoading = true
    // AnswersService.getAnswers().then(res => {
    //   this.answers = res.data
    //   this.currentPage = res.data.currentPage
    //   this.pages = res.data.pages
    //   this.isLoading = false
    // })

    const page = 0
    AnswersService.getAnswersByPage(page).then(res => {
      const { data } = res
      this.answers = data.content

      // this.pages = res.totalPages
      // this.currentPage = page
      this.isLoading = false
    })
  },
  async mounted () {
    // this.answers = (await AnswersService.index()).data
    // console.log(this.player)
  },
  methods: {
    changeDisplay (isList) {
      if (isList) {
        this.displayList = 'tm-products-list'
      } else {
        this.displayList = 'tm-products-grid'
      }
    }
  },
  computed: {
    player () {
      return this.$refs.player.player
    }
  }
}
</script>

<style scoped>
</style>
