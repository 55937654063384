import Api from '@/services/Api'

const resource = '/products'
const resourceq = '/questions'
export default {
  index (search) {
    return Api().get(`${resource}`, {
      params: {
        search: search
      }
    })
  },
  getAnswersByPage (params = {}) {
    return Api().get(`${resource}?size=12`, {
      params: params
    })
  },
  getAnswers (params = {}) {
    return Api().get(`${resource}`, { params: params })
  },

  getAnswersById (id) {
    return Api().get(`${resource}/${id}`)
  },

  getQuestionById (id) {
    return Api().get(`${resourceq}/${id}`)
  },
  getAnswersRelatedById (id) {
    return Api().get(`${resource}/${id}/related`)
  },
  show (answerId) {
    return Api().get(`${resource}/${answerId}`)
  },
  showquestion (qId) {
    return Api().get(`${resourceq}/${qId}`)
  },
  post (answer) {
    return Api().post(`${resource}`, answer)
  },
  put (answer) {
    return Api().put(`${resource}/${answer.id}`, answer)
  },

  getFeuturedDatas (params = {}) {
    return Api().get(`${resource}/feutured`, {
      params: params
    })
  },
  getKeys (params = {}) {
    return Api().get(`${resource}/keys`, {
      params: params
    })
  }
}
